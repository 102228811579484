import React, { useEffect, useState } from "react";
import Header from '../components/header'
import Footer from '../components/footer';
import { Helmet } from "react-helmet";

const ClientelePage = () => {

    // Client-side Runtime Data Fetching
   const [clientele, setClientele] = useState([])
   useEffect(() => {
       // get data from GitHub api
       fetch(`https://wordpress-906007-3157911.cloudwaysapps.com/wp-json/wp/v2/clienteles/?per_page=8`)
           .then(response => response.json()) // parse JSON from request
           .then(resultData => {
            setClientele(resultData);
           }) // parse JSON from request

   }, [])

   return (

    <div className='container-fluid'>
        <Header/>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Clienteles - Productlabs</title>
            <link rel="canonical" href="https://productlabs.us/" />
            <meta property="og:title" content="Clienteles - Productlabs" />
            <meta property="og:description" content="VETTED EXPERTS TO HELP EXECUTE PRODUCT BUILD" />
        </Helmet>
        <div className='client-detail-page'>
            <div className="clientele-section">
                <div className="container">
                    <div className="heading-wrapper">
                        <div className="heading-common-wrap">
                            <h2>Clientele</h2>
                        </div>
                    </div>
                    <div className="client-logo-grid">
                        {clientele.map(client => (
                            <div className="logoGrid" key={client.id}>
                                <div className="logoImage">              
                                    <a href={client.acf.company_url}  target="_blank">              
                                        <img src={client.acf.client_company_logo_image.url} alt="logo"></img> 
                                    </a>                      
                                </div>
                            </div>
                        ))}
                    </div>
                </div>                     
        </div>
        </div>
        <Footer/>
    </div>
)
}

export default ClientelePage;